<template>
  <div class="Banking">
    <BannerImg :img-url="$StaticFileUrl + '/mp/BannerImage/bag_bank_p_5.jpg'">
      <div class="title wow fadeInDown" data-wow-duration="1s">银行场景化营销服务一站式解决方案</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">提供客户“金融+生活”全方位私域流量运营解决方案</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即咨询</div>
        <div class="btn-red-linear wow flipInX" data-wow-duration="1s" style="margin-top: 100px">免费试用</div>
      </div>
    </BannerImg>
  </div>
</template>

<script>
import WOW from 'wowjs'

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
import BannerImg from '@/components/BannerImg'
export default {
  name: 'Banking',
  components: {
    BannerImg,
  },
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
